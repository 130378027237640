* { border: 0; padding: 0; margin: 0; }

html, body {
    height: 100%;
    width: 100%;
    position: fixed;
    font-family: 'Joan', serif;
}

#root, .App {
  height: 100%;
  width: 100%;
  background-color: black;
  color: white;
}

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.titleBar {
  width: 100%;
  text-align: center;
  color: white;
  border-bottom: thin solid #c0c0c0;
  padding: 0.5em;
  margin-bottom: 2em;
}

.preload { display: none; }

.instructions {
  margin-bottom: 1em;
}

.content {
  height: 100%;
  width: 75%;
  max-width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.plantPhoto {
  max-width: 100%;
  width: auto;
  height: auto;
  max-height: 70%;
}

.flipped {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}

.nameReveal {
  width: 100%;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1.5em;
  text-align: center;
}

.notRevealed { cursor: pointer; }
.revealed { cursor: auto; }

.licenseLink {
  margin-top: 1em;
  color: #c0c0c0;
  font-size: smaller;
  font-style: italic;
}
